﻿.news-item-header-section {
    padding: 15px 0;
}

.news-item-header {

    h1 {
        text-transform: uppercase;
        @include text-props(24px, 600, 29px);
        margin-bottom: 15px;
    }

    span {
        @include text-props(14px, 400, 17px);
    }

    img {
        width: 100%;
    }
}


.other-news {
    display: flex;
    flex-direction: column;

    .side-menu__news-item {
        border-bottom: solid 1px $gray-400-blur-20;
        display: flex;
        flex-direction: row;
        padding: 19px 0;

        &:only-child {
            border-bottom: 0;
        }

        &:last-child {
            border-bottom: 0;
        }

        .other-news__image {
            border: solid 1px $gray-400-blur-20;
            width: 25%;
            flex: 0 0 25%;
            display: flex;

            img {
                width: 100%;
                height: auto;
                object-fit: contain;
            }
        }

        .other-news__text {
            padding-left: 10px;
            width: 75%;
            flex: 0 0 75%;

            @include text-props(14px, 600, 17px);
            
            > div {
                margin-bottom: 10px;
            }
            
            > span {
                @include text-props(14px, 400, 17px)
            }
        }
    }
}
