﻿.section-video-section {
    padding: 60px 0;


    @media screen and (min-width: 1024px) {
        padding: 80px 0;
    }

}

.sec-video__title {

    h2 {
        margin-bottom: 24px;
        &,
        & > * {
            @include text-props(24px, 600, 29px);
            text-transform: uppercase;
        }
    }
}

.video-container {
    position: relative;
    padding-bottom: 56.25%;


    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
