﻿.inpage-navigation {
    padding: 10px 15px;

    &-inner {
        display: flex;
        padding: 0;
        justify-content: space-between;

        > div {
            flex: 0 0 30%;
            width: 30%
        }

        a {
            @include text-props(14px, 400, 17px, $white);
            color: $white;

            &:hover,
            &:active,
            &:focus,
            &:focus-within {
                text-decoration: underline;
            }
        }
    }
    
    & + .paragraph-section {
        padding: 80px 0 !important
    }
}
