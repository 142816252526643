//
// Text Mixins
//
/*@mixin text-hover-variant($color) {
  @include transition($transition-base);
    &:hover {
      color: $color;
    }
}
*/

@mixin text-props($fsize, $fweight, $lnheight, $color: $gray-400) {
    font-size: $fsize;
    font-weight: $fweight;
    line-height: $lnheight;
    color: $color;
}