﻿.news-section {
    padding: 60px 0;

    .description {

        p {
            line-height: 17px;

            a {
                color: $purple;
                cursor: pointer;

                &:hover,
                &:focus,
                &:active,
                &:visited {
                    color: $purple;
                    text-decoration: underline;
                }
            }
        }
    }

    @media screen and (min-width: 1024px) {
        padding: 80px 0;
    }
}

.news-articles-container {
    .article-container {
        display: flex;
        flex-direction: column;
        @include text-props(14px, 400, 17px);

        .img-preview {
            border-radius: 6px;
            border: solid 1px $gray-400-blur-20;
            padding: 0 30px;
            position: relative;
            overflow: hidden;

            &:before {
                content: "";
                display: block;
                padding-top: 100%;
            }

            img {
                bottom: 0;
                height: auto;
                left: 30px;
                max-width: calc(100% - 60px);
                position: absolute;
                right: 30px;
                top: 50%;
                transform: translateY(-50%);
                width: 100%;
            }
        }

        .article-title {
            @include text-props(16px, 600, 19px);
        }

        p {
            @include text-props(14px, 400, 17px)
        }

        a {
            @include text-props(14px, 600, 17px);
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        .view-here {
            @include text-props(14px, 600, 17px, $purple);
        }
    }
}
