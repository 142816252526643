﻿.annual-reports-section {
    padding: 60px 0;

    h2 {
        @include text-props(24px, 600, 29px);
        margin-bottom: 15px;
    }

    p {
        margin-bottom: 24px;
    }

    a {
        color: $gray-400;
        text-decoration: none;
        line-height: 17px;

        &:hover {
            color: $gray-400;
            text-decoration: underline;
        }

        &:focus,
        &:focus-within,
        &:active,
        &:visited {
            color: $gray-400;
            text-decoration: none;
        }
    }

    .accordion .ac-item {
        border: 0;
        border-radius: 0;
        padding: 0;

        .ac-title {
            background-color: $gray-400-blur-20;
            padding: 10px 15px;
            border-radius: 5px;
            font-size: 15px;
            font-weight: 600;

            &:before {
                right: 20px;
                color: #fff;
                background-color: purple;
                width: 24px;
                text-align: center;
            }
        }

        .ac-content {
            padding: 10px 15px;
        }
    }

    .report-item {
        display: flex;
        flex-direction: row;
        padding: 10px 15px;
        border-bottom: solid 1px $gray-400-blur-20;
        justify-content: space-between;

        &:last-child {
            border-bottom: 0;
        }
    }

    @media screen and (min-width: 1024px) {
        padding: 80px 0;
    }
}
