﻿.error-section {
    background-color: $purple;
    padding: 80px 30px;

    .error-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .status {
            flex: 0 0 100%;
            width: 100%;
            margin-bottom: 40px;
            @include text-props (147px, 900, 178px, $white);
        }

        .text {
            flex: 0 0 100%;
            width: 100%;

            .header {
                @include text-props (36px, 500, 44px, $white);
                text-transform: uppercase;
                margin-bottom: 20px;
            }

            .message {
                @include text-props (14px, 500, 17px, $white);
                margin-bottom: 20px;
            }
        }
    }

    @media (min-width: 1024px) {
        padding: 170px 30px !important;


        .error-wrapper {
            align-items: center;

            .status {
                @include text-props (224px, 900, 271px, $white);
                flex: 0 0 50%;
                width: 50%;
                text-align: right;
                padding-right: 15px;
            }

            .text {
                flex: 0 0 50%;
                width: 50%;
                padding-left: 15px
            }
        }
    }
}
