﻿

.with-side-menu {
    max-width: 1141px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: auto;


    .page-title {
        padding-top: 60px;
        padding-bottom: 15px;
        @include text-props(16px, 600, 23px)
    }

    .page-title h1 {
        @include text-props(16px, 600, 23px)
    }

    .section-headers-index {
        display: none;
    }

    .section-headers-index__sm-select {
        /*padding: 0 30px;*/

        select {
            padding: 0 30px 0 10px !important;
            -webkit-padding-end: 30px !important;
            -webkit-padding-start: 10px !important;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 100%;
            background: #fff;
            border: solid 1px $gray-400-blur-20;
            border-radius: 6px;
            height: 37px;
            // Arrow
            background: transparent;
            background-image: url("data:image/svg+xml;utf8,<svg fill='%23777777' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
            background-repeat: no-repeat;
            background-position-x: 100%;
            background-position-y: 5px;
            // Text / Options
            font-weight: 400;
            font-size: 14px;
            color: $gray-400;
            appearance: none;
            position: relative;
        }
    }

    .side-menu,
    .sections {
        width: 100%;
    }


    &__right {
        .sections {
            padding: 30px 0;
            width: 100%;
        }

        .side-menu__right {
            padding: 30px !important;
        }

        .suggestions {
            margin-bottom: 15px;
            @include text-props(16px, 600, 23px);
        }

        @media all and (min-width: 1024px) {

            .sections {
                width: 70%;
                padding-top: 15px;
                padding-bottom: 15px;
                padding-right: 15px;
            }

            .side-menu__right {
                width: 30%;
                padding: 15px 0 !important;

            }


            .suggestions {
                display: block;
                line-height: .7;
                @include text-props(16px, 600, .7);
            }
        }
    }


    @media all and (min-width: 1024px) {
        .side-menu {
            width: 25%;
            padding: 80px 15px 0;
            height: 25vh;
            min-height: 400px;
            overflow: visible;
            position: -webkit-sticky;
            position: sticky;
            top: 5%;

            .page-title {
                font-weight: 600;
                font-size: 16px;
                line-height: 23px;
                padding: 0;
            }

            .section-headers-index {
                display: flex;
                flex-direction: column;

                .section-header {
                    padding: 16px 16px 16px 16px;
                    border-bottom: solid 1px #77777733;
                    text-transform: capitalize;
                    @include text-props(14px, 400, 17px, $gray-500);

                    
                    &:hover {
                        text-decoration: underline;
                    }

                    &.selected {
                        font-weight: 600;
                    }
                }
            }
        }

        .sections {
            width: 75%;
            padding: 0 40px;
        }

        .section-headers-index__sm-select {
            display: none;
        }
    }
}
