﻿.grouped-paragraph-section {
    padding: 60px 0;

    .main-title {
        margin-bottom: 15px;
    }

    div[class="col-count-*"] {
        column-count: 1;
    }

    .col-count-1,
    .col-count-2,
    .col-count-3,
    .col-count-4 {
        column-count: 1;
    }

    h2 {
        @include text-props(16px,600, 19px, $purple);
        word-break: break-word;
        hyphens: auto;
    }

    p, li {
        @include text-props(14px,400, 17px)
    }

    ul {
        padding-left: 30px;
    }

    @media screen and (min-width: 1024px) {
        padding: 80px 0 40px;

        div[class="col-count-*"] {
            column-gap: 30px;
        }

        .col-count-1 {
            column-count: 1;
        }

        .col-count-2 {
            column-count: 2;
        }

        .col-count-3 {
            column-count: 3;
        }

        .col-count-4 {
            column-count: 4;
        }
    }
}
