﻿.details-section {
    min-height: 480px;
    display: flex;
    padding: 100px 0 70px;
    color: $white;

    h2 {
        color: $white;
        @include text-props(34px, 700, 41px)
    }

    p {
        @include text-props(16px, 400, 19px)
    }

    .container > div > * {
        margin-bottom: 24px;
        color: $white;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .btn {
        padding-top: 11px !important;
        padding-bottom: 11px !important;
        height: auto !important;
        line-height: 1 !important;
        margin-bottom: 0;
    }

    @media screen and (min-width: 1200px) {
        padding: 110px 0;

        .container > div {
            max-width: 660px;
            margin: auto;
        }
    }
}
