﻿section.image-row-section {
    padding: 0;
    
    img {
        width: 100%;
        height: auto;
        max-width: 100%;
        object-fit: contain;
    }
}
