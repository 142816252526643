﻿.contact-persons-section {
    padding: 60px 0 30px;

    .contact-header-text {
        @include text-props(14px, 600, 17px);
    }

    .employee-item .employee-details .employee-description {
        @include text-props(12px, 400, 15px);
        max-height: unset;

        a {
            @include text-props(12px, 400, 15px, $gray-500);

            &:hover {
                text-decoration: underline;
            }
        }
    }

    @media screen and (min-width: 1024px) {
        padding: 80px 0 40px;
    }

    & .employee-item .employee-details {
        padding: 15px;

        span.employee-company {
            margin-bottom: 8px;
        }
    }
}
