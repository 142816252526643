﻿.project-updates-section {
    padding: 60px 0;

    h2 {
        letter-spacing: 0;
        color: $purple;
        @include text-props(20px, 600, 24px, $purple);
    }

    .columns {
        column-width: 250px;
        column-gap: 15px;
        width: 100%;
        max-width: 1140px;
        margin: 30px auto;
    }

    .year {
        break-inside: avoid-column;
        margin-bottom: 20px;

        ul {
            padding-left: 12px;

            li {
                list-style: none;

                a {
                    color: $gray-400;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;
                    text-decoration: underline;

                    &:hover,
                    &:active,
                    &:focus {
                        color: $gray-400;
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    @media screen and (min-width: 1024px) {
        padding: 80px 0;

        .year {
            ul {
                li {
                    a {
                        text-decoration: none;

                        &:hover,
                        &:active,
                        &:focus,
                        &:focus-visible,
                        &:focus-within {
                            color: $gray-400;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
}
