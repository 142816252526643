﻿.paragraph-section {
    padding-top: 60px;
    padding-bottom: 15px;

    & ~ & {
        padding-top: 30px;
        padding-bottom: 15px;
    }

    h2 {
        color: $purple;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
    }

    p {
        color: $gray-400;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
    }

    a {
        color: $purple;
        text-decoration: underline;
        cursor: pointer;

        &:hover,
        &:focus,
        &:active,
        &:visited {
            color: $purple;
            text-decoration: underline;
        }
    }

    @media screen and (min-width: 1024px) {
        padding-top: 80px;
    }
}


section:not(.paragraph-section) ~ .paragraph-section {
    padding-bottom: 60px;
}

section:not(.paragraph-section) + .paragraph-section {
    padding-bottom: 40px;
}


@media screen and (min-width: 1024px) {

    section:not(.paragraph-section) ~ .paragraph-section {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    section:not(.paragraph-section) + .paragraph-section {
        padding-top: 40px;
        padding-bottom: 40px;
    }

}