﻿.employee-list-section {
    padding: 60px 0;

    h2 {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 15px;
    }

    .employee-list {
        display: flex;
        flex-wrap: wrap;
    }

    .employee-item {
        width: 100%;
        padding: 0;
        margin-bottom: 30px;

        &-inner {
            border: solid 1px #77777733;
            border-radius: 6px;
            display: flex;
            height: 165px;

            .employee-image {
                height: 165px;

                img {
                    border-top-left-radius: 6px;
                    border-bottom-left-radius: 6px;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        .employee-details {
            padding: 6px 15px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-content: baseline;

            span {
                width: 100%;
                display: block;
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                margin-bottom: 7px;

                &.employee-name {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 17px;
                }

                &.employee-company {
                    font-size: 14px;
                    line-height: 17px;
                    margin-bottom: 0;

                    & + .employee-company {
                        margin-bottom: 7px;
                    }
                }
            }

            .employee-description {
                overflow: hidden;
                max-height: calc(15px * 2);
                /*margin-bottom: 20px;*/
                display: block;

                p {
                    font-size: 12px;
                    line-height: 15px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-weight: 400;
                    max-height: calc(15px * 2);
                    -webkit-line-clamp: 2;
                    /*margin-bottom: 20px;*/
                    margin-bottom: 0;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                }
            }

            a {
                @include text-props(14px, 600, 17px, $purple);
                align-self: flex-end;
                margin-top: 20px;

                &.read-more {
                    display: block;
                }

                &.read-less {
                    display: none;
                }
            }
        }

        &.expand {
            height: auto;

            .employee-item-inner {
                height: auto;

                .employee-image {
                    max-height: 165px;
                    border-radius: 6px;

                    img {
                        border-radius: 6px;
                    }
                }
            }

            .employee-details {

                .employee-description {
                    max-height: unset;
                }

                p {
                    max-height: unset;
                    display: block;
                }

                a {

                    &.read-more {
                        display: none;
                    }

                    &.read-less {
                        display: block;
                    }
                }
            }
        }
    }

    @media (min-width: 1024px) {
        padding: 80px 0;


        .employee-item {
            width: 50%;
            flex: 0 0 50%;
            padding-left: 15px;
            padding-right: 15px;

            &:nth-last-child(-n + 2) {
                margin-bottom: 0;
            }


            .employee-details {

                .employee-description {
                    max-height: calc(15px * 4);
                    -webkit-line-clamp: 4;
                    /*margin-bottom: 20px;*/

                    p {
                        max-height: calc(15px * 4);
                        -webkit-line-clamp: 4;
                    }
                }
            }

            &-inner {
                border: solid 1px #77777733;
                border-radius: 6px;
                display: flex;
                height: 176px;

                .employee-image {
                    height: 174px;

                    img {
                        border-top-left-radius: 6px;
                        border-bottom-left-radius: 6px;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }

            &.expand {
                height: auto;

                .employee-item-inner {
                    height: auto;

                    .employee-image {
                        max-height: 174px;
                    }
                }

                .employee-details {

                    .employee-description {
                        max-height: unset;
                    }

                    p {
                        max-height: unset;
                    }

                    a {

                        &.read-more {
                            display: none;
                        }

                        &.read-less {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}

/*
.employee-list-section:not(.contact-persons-section) {
    .employee-item {

        .employee-description {
            position: relative;

            &:after {
                position: absolute;
                content: "...";
                right: 0;
                bottom: 0;
            }
        }

        &.expand {
            .employee-description {
                &:after {
                    content: none
                }
            }
        }
    }
}*/