/* ----------------------------------------------------------------
    Search
-----------------------------------------------------------------*/
#search {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: $white;
    line-height: normal;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.5s;

    #search-logo {
        top: -1px;
        left: 30px;
        opacity: 0;
        transition: opacity 0.5s;
        position: absolute;
        line-height: 80px;
        height: 80px;
        width: auto;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .search-form {
        opacity: 0;
        transform: scale3d(0.8, 0.8, 1);
        transition: opacity 0.5s, transform 0.5s;
        margin: 80px 0;

        label {
            @include text-props(16px, 600, 19px, $gray-400);
            position: absolute;
            text-align: left;
            top: 0;
            z-index: -1;
        }

        .text-muted {
            opacity: .4;
        }

        .invalid-feedback {
            @include text-props(14px, 400, normal, $error)
        }

        .form-control {
            border-width: 0px 0px 2px 0px;
            border-radius: 0px;
            padding: 10px 0;
            border-color: $gray-400;
            margin-bottom: 20px;
            background-color: transparent;
            @include text-props(16px, 600, 19px, $gray-500);

            &:focus {
                background-color: transparent;
                outline: none;
                box-shadow: none;
            }

            &:not(:placeholder-shown) + label {
                visibility: hidden;
            }

            &:-webkit-input-placeholder,
            &:-moz-placeholder,
            &:-ms-input-placeholder {
                color: $gray-500;
            }

            &:focus,
            &:focus-within,
            &:active {
                background-color: $purple-light;
            }
        }
    }

    .btn-search-close {
        font-size: 2em;
        position: absolute;
        top: 20px;
        right: 30px;
        display: none;
        padding: 12px;
        line-height: 12px;
        background-color: $purple;
        border: 0;
        cursor: pointer;
        border-radius: $border-radius;
        color: $white;
        opacity: 0;
        transform: scale3d(0.8, 0.8, 1);
        transition: opacity 0.5s, transform 0.5s;
    }

    .search-suggestion-wrapper {
        display: none;
    }
}

/* ----------------------------------------------------------------
    Search / Open state
-----------------------------------------------------------------*/
body.search-open {
    #topbar {
        z-index: 199;
    }

    #search {
        z-index: 201;
        pointer-events: auto;
        opacity: 1;

        #search-logo {
            display: block;
            opacity: 1;
        }

        .search-form {
            opacity: 1;
            transform: scale3d(1, 1, 1);

            .lg-help-text {
                display: block;
            }

            .sm-help-text {
                display: none;
            }

            .search-submit {
                display: none;
            }
        }

        .btn-search-close {
            display: block;
            opacity: 1;
            transform: scale3d(1, 1, 1);
        }

        .search-suggestion {
            opacity: 1;
            transform: translate3d(0, 0, 0);

            &:nth-child(2) {
                transition-delay: 0.1s;
            }

            &:nth-child(3) {
                transition-delay: 0.2s;
            }
        }
    }
}

/* ----------------------------------------------------------------
    Search Responsive
-----------------------------------------------------------------*/
@include media-breakpoint-down(lg) {
    #search {
        #search-logo {
            margin: 0 auto !important;
            max-width: 130px;
            left: auto;
        }

        .search-form {
            margin: 40px;

            .form-control {
                font-size: 24px;
                margin-bottom: 8px;
            }

            .text-muted {
                font-size: 10px;
            }
            
            .invalid-feedback {
                @include text-props(10px, 400, normal, $error)
            }
        }

        .btn-search-close {
            font-size: 1em;
            padding: 8px;
            line-height: 8px;
        }
    }
}

@media only screen and (hover: none) and (pointer: coarse) {
    #search {
        .search-form {
            .lg-help-text {
                display: none !important;
            }

            .sm-help-text {
                display: block !important;
            }

            .search-submit {
                display: block !important;
            }
        }
    }
}

.search-results-section {
    padding: 60px 0;
    background-color: $gray-400-blur-20;

    h1 {
        @include text-props(20px, 500, 24px, $gray-500);
        margin-bottom: 30px !important
    }

    @media screen and (min-width: 1024px) {
        padding: 80px 0;
    }
}

.search-results-container {
    padding: 30px;
    background-color: $white;

    .search-result-header {
        padding-bottom: 30px;
        border-bottom: solid 1px $gray-400-blur-20;
    }

    .search-sort-wrapper{
        position: relative;

        &:after {
            content: "";
            border-color: $gray-500 transparent transparent transparent;
            border-style: solid;
            border-width: 6px 6px;
            position: absolute;
            right: 12px;
            top: 18px;
        }
    }

    #sortSearchBy {
        @include text-props(14px, 400, 17px, $gray-400);
        outline: 0;
        appearance: none;
        padding: 12px 32px 12px 16px;
        border: 0;
        background-color: $white;
        border: solid 1px $gray-400-blur-20;
        border-radius: 6px;
        position: relative;
        
        &::-ms-expand {
            display: none;
        }
    }

    .search-results {
        padding-top: 30px;

        .search-result-item {
            margin-bottom: 30px;

            a {
                cursor: pointer;

                .search-title {
                    @include text-props(16px, 400, 25px, $blue-link);
                }

                .search-url {
                    @include text-props(14px, 400, 23px, $green-link);
                }

                .search-description {
                    @include text-props(14px, 400, 17px, $gray-500);
                }

                &:hover,
                &:focus,
                &:focus-within,
                &:active {

                    .search-title,
                    .search-url {
                        text-decoration: underline;
                    }

                    .search-description {
                        text-decoration: none;
                    }
                }
            }
        }
    }
}
