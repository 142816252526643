﻿.back-link a {
    @include text-props(14px, 600, 17px, $gray-500);
}

.project-update-title {
    padding: 20px 15px 20px;

    h1 {
        @include text-props(20px, 600, 24px)
    }
}

.project-updates-days {
    padding: 20px 0;

    .task-description {
        @include text-props(14px, 600, 17px);
        word-wrap: break-word;
    }

    h2 {
        @include text-props(20px, 600, 24px, $purple);
        margin-bottom: 30px;
    }

    .day-container {
        margin-bottom: 30px;
    }

    .day-header {
        @include text-props(14px, 600, 17px);
        text-transform: uppercase;
    }

    .day-image {
        position: relative;
        padding-bottom: 78%;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
    }

    span {
        @include text-props(14px, 600, 17px);
    }
}

@media screen and (min-width: 1024px) {

    .project-updates-days {

        .task-description {
            /*@include text-props(14px, 600, 17px);*/
            padding-top: 30px;
        }


        .day-image {
            position: relative;
            padding-bottom: 78%;

            img {

            }
        }
    }
}
