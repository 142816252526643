// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.
// Color system
$white: #fff !default;
$red: #ff0000 !default;
$error: #dc3545 !default;
$gray-100: #fbfbfb !default;
$gray-200: #f9f9fa !default;
$gray-300: #e4e6ef !default; //chkd #ecedf1 !default;
$gray-400: #777777 !default;
$gray-400-blur-20: #77777733 !default;
$gray-500: #796e65 !default;
$gray-600: #9aa0a6 !default; //chkd
$gray-700: #2e2e2e !default; //chkd
$gray-800: #2e2e2e !default;
$gray-900: #1e2022 !default; //chkd
$black: #000000 !default;

$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900,
) !default;

$theme: #2250fc !default; //default theme color
$blue: #007bff !default;
$blue-link: #1155CC !default;
$indigo: #6610f2 !default;
/*$purple: #6f42c1 !default;*/
$purple: #5f2167 !default;
$purple-light: #511c6c1a !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$green-link: #009933 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

$colors: (
  "blue": $blue,
  "indigo": $indigo,
  "purple": $purple,
  "pink": $pink,
  "red": $red,
  "orange": $orange,
  "yellow": $yellow,
  "green": $green,
  "teal": $teal,
  "cyan": $cyan,
  "white": $white,
  "gray": $gray-600,
  "gray-dark": $gray-800,
) !default;

$primary: $theme !default;
$secondary: $gray-500 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-900 !default;
$blue: $blue !default;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "white": $white,
  "light": $light,
  "dark": $dark,
  "blue": $blue,
) !default;

//Social Network Colors
$facebook: #1877f2 !default;
$twitter: #1da1f2 !default;
$youtube: #ff0000 !default;
$instagram: #c32aa3 !default;
$pinterest: #bd081c !default;
$linkedin: #007bb5 !default;
$google: #4285f4 !default;
$snapchat: #fffc00 !default;
$whatsup: #25d366 !default;
$tumblr: #35465d !default;
$redit: #ff4500 !default;
$tiktok: #010101 !default;
$yelp: #d32323 !default;
$spotify: #1ed760 !default;
$apple: #a6b1b7 !default;
$vimeo: #1ab7ea !default;
$skype: #00aff0 !default;
$android: #a4c639 !default;
$dribbble: #ea4c89 !default;
$slack: #4a154b !default;
$github: #222222 !default;
$flickr: #f40083 !default;

$brand-colors: (
  "facebook": $facebook,
  "twitter": $twitter,
  "youtube": $youtube,
  "instagram": $instagram,
  "pinterest": $pinterest,
  "linkedin": $linkedin,
  "google": $google,
  "snapchat": $snapchat,
  "whatsup": $whatsup,
  "tumblr": $tumblr,
  "redit": $redit,
  "tiktok": $tiktok,
  "yelp": $yelp,
  "spotify": $spotify,
  "apple": $apple,
  "vimee": $vimeo,
  "skype": $skype,
  "android": $android,
  "dribbble": $dribbble,
  "slack": $slack,
  "github": $github,
  "flickr": $flickr,
) !default;

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio: 1 !default;
// Customize the light and dark text colors for use in our color contrast function.
$color-contrast-dark: $black !default;
$color-contrast-light: $white !default;
$blue-100: tint-color($blue, 80%) !default;
$blue-200: tint-color($blue, 60%) !default;
$blue-300: tint-color($blue, 40%) !default;
$blue-400: tint-color($blue, 20%) !default;
$blue-500: $blue !default;
$blue-600: shade-color($blue, 20%) !default;
$blue-700: shade-color($blue, 40%) !default;
$blue-800: shade-color($blue, 60%) !default;
$blue-900: shade-color($blue, 80%) !default;
$indigo-100: tint-color($indigo, 80%) !default;
$indigo-200: tint-color($indigo, 60%) !default;
$indigo-300: tint-color($indigo, 40%) !default;
$indigo-400: tint-color($indigo, 20%) !default;
$indigo-500: $indigo !default;
$indigo-600: shade-color($indigo, 20%) !default;
$indigo-700: shade-color($indigo, 40%) !default;
$indigo-800: shade-color($indigo, 60%) !default;
$indigo-900: shade-color($indigo, 80%) !default;
$purple-100: tint-color($purple, 80%) !default;
$purple-200: tint-color($purple, 60%) !default;
$purple-300: tint-color($purple, 40%) !default;
$purple-400: tint-color($purple, 20%) !default;
$purple-500: $purple !default;
$purple-600: shade-color($purple, 20%) !default;
$purple-700: shade-color($purple, 40%) !default;
$purple-800: shade-color($purple, 60%) !default;
$purple-900: shade-color($purple, 80%) !default;
$pink-100: tint-color($pink, 80%) !default;
$pink-200: tint-color($pink, 60%) !default;
$pink-300: tint-color($pink, 40%) !default;
$pink-400: tint-color($pink, 20%) !default;
$pink-500: $pink !default;
$pink-600: shade-color($pink, 20%) !default;
$pink-700: shade-color($pink, 40%) !default;
$pink-800: shade-color($pink, 60%) !default;
$pink-900: shade-color($pink, 80%) !default;
$red-100: tint-color($red, 80%) !default;
$red-200: tint-color($red, 60%) !default;
$red-300: tint-color($red, 40%) !default;
$red-400: tint-color($red, 20%) !default;
$red-500: $red !default;
$red-600: shade-color($red, 20%) !default;
$red-700: shade-color($red, 40%) !default;
$red-800: shade-color($red, 60%) !default;
$red-900: shade-color($red, 80%) !default;
$orange-100: tint-color($orange, 80%) !default;
$orange-200: tint-color($orange, 60%) !default;
$orange-300: tint-color($orange, 40%) !default;
$orange-400: tint-color($orange, 20%) !default;
$orange-500: $orange !default;
$orange-600: shade-color($orange, 20%) !default;
$orange-700: shade-color($orange, 40%) !default;
$orange-800: shade-color($orange, 60%) !default;
$orange-900: shade-color($orange, 80%) !default;
$yellow-100: tint-color($yellow, 80%) !default;
$yellow-200: tint-color($yellow, 60%) !default;
$yellow-300: tint-color($yellow, 40%) !default;
$yellow-400: tint-color($yellow, 20%) !default;
$yellow-500: $yellow !default;
$yellow-600: shade-color($yellow, 20%) !default;
$yellow-700: shade-color($yellow, 40%) !default;
$yellow-800: shade-color($yellow, 60%) !default;
$yellow-900: shade-color($yellow, 80%) !default;
$green-100: tint-color($green, 80%) !default;
$green-200: tint-color($green, 60%) !default;
$green-300: tint-color($green, 40%) !default;
$green-400: tint-color($green, 20%) !default;
$green-500: $green !default;
$green-600: shade-color($green, 20%) !default;
$green-700: shade-color($green, 40%) !default;
$green-800: shade-color($green, 60%) !default;
$green-900: shade-color($green, 80%) !default;
$teal-100: tint-color($teal, 80%) !default;
$teal-200: tint-color($teal, 60%) !default;
$teal-300: tint-color($teal, 40%) !default;
$teal-400: tint-color($teal, 20%) !default;
$teal-500: $teal !default;
$teal-600: shade-color($teal, 20%) !default;
$teal-700: shade-color($teal, 40%) !default;
$teal-800: shade-color($teal, 60%) !default;
$teal-900: shade-color($teal, 80%) !default;
$cyan-100: tint-color($cyan, 80%) !default;
$cyan-200: tint-color($cyan, 60%) !default;
$cyan-300: tint-color($cyan, 40%) !default;
$cyan-400: tint-color($cyan, 20%) !default;
$cyan-500: $cyan !default;
$cyan-600: shade-color($cyan, 20%) !default;
$cyan-700: shade-color($cyan, 40%) !default;
$cyan-800: shade-color($cyan, 60%) !default;
$cyan-900: shade-color($cyan, 80%) !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.
$enable-caret: true !default;
$enable-rounded: true !default;
$enable-shadows: true !default;
$enable-gradients: false !default;
$enable-transitions: true !default;
$enable-reduced-motion: true !default;
$enable-smooth-scroll: true !default;
$enable-grid-classes: true !default;
$enable-button-pointers: true !default;
$enable-rfs: true !default;
$enable-validation-icons: false !default;
$enable-negative-margins: true !default;
$enable-deprecation-messages: true !default;
$enable-important-utilities: true !default;

// Prefix for :root CSS variables
$variable-prefix: bs- !default;

// Gradient
//
// The gradient which is added to components if `$enable-gradients` is `true`
// This gradient is also added to elements with `.bg-gradient`
$gradient: linear-gradient(
  180deg,
  rgba($white, 0.15),
  rgba($white, 0)
) !default;
// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.
$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: calc($spacer / 4),
  2: calc($spacer / 2),
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: (
    $spacer * 4.5,
  ),
  7: (
    $spacer * 5.5,
  ),
  8: (
    $spacer * 7,
  ),
  9: (
    $spacer * 9,
  ),
  10: (
    $spacer * 10,
  ),
) !default;
// Position
//
// Define the edge positioning anchors of the position utilities.
$position-values: (
  0: 0,
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%,
) !default;
// This variable affects the `.h-*` and `.w-*` classes.
$sizes: (
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%,
  auto: auto,
) !default;
$letter-spacing: 0; //.03125rem !default;
// Body
//
// Settings for the `<body>` element.
$body-bg: $white !default;
$body-color: #3c4043 !default; //chkd
$body-text-align: null !default;
$body-letter-spacing: $letter-spacing !default;
// Links
//
// Style anchor elements.
$link-color: $primary !default;
$link-decoration: none !default;
$link-shade-percentage: 15% !default;
$link-hover-color: shift-color($link-color, $link-shade-percentage) !default;
$link-hover-decoration: null !default;
$stretched-link-pseudo-element: after !default;
$stretched-link-z-index: 1 !default;
// Paragraphs
//
// Style p element.
$paragraph-margin-bottom: 1rem !default;
// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
// scss-docs-start grid-breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
) !default;
// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.
// scss-docs-start container-max-widths
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1141px,
) !default;
// Grid columns
//
// Set the number of columns and specify the width of the gutters.
$grid-columns: 12 !default;
$grid-gutter-width: 2rem !default;
$grid-row-columns: 6 !default;
$gutters: $spacers !default;
// Container padding
$container-padding-x: calc($grid-gutter-width / 2 );
// Components
//
// Define common padding and border radius sizes and more.
$border-width: 1px !default;
$border-widths: (
  0: 0,
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px,
) !default;
$border-color: $gray-300 !default;
$border-radius: 0.42rem !default;
$border-radius-xs: 0.2rem !default;
$border-radius-sm: 0.375rem !default;
$border-radius-lg: 1rem !default;
$border-radius-xl: 1.2rem !default;
$rounded-pill: 50rem !default;
$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.08) !default;
$box-shadow: 0 6px 12px rgba(140, 152, 164, 0.075); //0 0.2rem 0.80rem rgba(134, 145, 155, 0.1) !default;
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.175) !default;
$box-shadow-xl: 0 1.5rem 4rem rgba($black, 0.175) !default;
$component-active-color: $white !default;
$component-active-bg: $primary !default;
// Caret
$caret-width: 0.3em !default;
$caret-vertical-align: $caret-width * 0.85 !default;
$caret-spacing: $caret-width * 0.85 !default;
// Transitions
$transition-base-duration: 0.2s;
$transition-base: all $transition-base-duration ease !default;
$transition-fade: opacity $transition-base-duration linear !default;
$transition-collapse: height $transition-base-duration ease !default;
$transition-cubic-bezier: all $transition-base-duration
  cubic-bezier(0.77, 0, 0.2, 2.25) !default;
$transition-bg: background-color $transition-base-duration * 2 linear !default;
$transition-ease-in-out: all $transition-base-duration ease-in-out !default;
// Aspect Ratio
$aspect-ratios: (
  "1x1": 100%,
  "4x3": calc(3 / 4 * 100%),
  "16x9": calc(9 / 16 * 100%),
  "21x9": calc(9 / 21 * 100%),
) !default;
// Bg variants
$bg-soft-bg-scale: -85% !default;
// Bg variants
$color-soft-scale: -10% !default;
// Typography
//
// Font, line-height, and color for body text, headings, and more.
/*$font-family-base: "Poppins", sans-serif !default;*/
$font-family-base: "Inter", sans-serif !default;
// stylelint-enable value-keyword-case
// $font-size-root effects the value of `rem`, which is used for as well font sizes, paddings and margins
// $font-size-base effects the font size of the body text
$font-size-root: 14px !default;
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-xs: $font-size-base * 0.75 !default;
$font-size-sm: $font-size-base * 0.875 !default;
$font-size-lg: $font-size-base * 1.25 !default;
$font-size-xl: $font-size-base * 1.5 !default;
$font-size-xxl: $font-size-base * 5 !default;
$utilities: (
  "font-size": (
    property: font-size,
    class: font-size,
    values: (
      xs: $font-size-xs,
      sm: $font-size-sm,
      md: $font-size-base,
      lg: $font-size-lg,
      xl: $font-size-xl,
      xxl: $font-size-xxl,
    ),
  ),
  "opacity": (
    property: opacity,
    class: o,
    values: (
      0: 0,
      1: 0.1,
      2: 0.2,
      3: 0.3,
      4: 0.4,
      5: 0.5,
      6: 0.6,
      7: 0.7,
      8: 0.8,
      9: 0.9,
      10: 1,
    ),
  ),
);
$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-semi-bold: 500 !default;
$font-weight-bold: 600 !default;
$font-weight-bolder: 700 !default;
$font-weight-base: $font-weight-normal !default;
$line-height-base: 1.5 !default;
$line-height-sm: 1.25 !default;
$line-height-lg: 2 !default;
$line-height-xl: 2.5 !default;
$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.7 !default;
$h4-font-size: $font-size-base * 1.35 !default;
$h5-font-size: $font-size-base * 1.175 !default;
$h6-font-size: $font-size-base * 1.08 !default;
$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
) !default;
$headings-margin-bottom: calc($spacer / 2);
$headings-font-family: inherit !default;
$headings-font-weight: 600 !default;
$headings-line-height: 1.8 !default;
$headings-color: $gray-900 !default;
$display-font-sizes: (
  1: 6rem,
  2: 5.5rem,
  3: 4.5rem,
  4: 3.5rem,
  5: 3rem,
  6: 2.5rem,
) !default;
$display-font-weight: 300 !default;
$display-line-height: $headings-line-height !default;
$lead-font-size: $font-size-base * 1.25 !default;
$lead-font-weight: 400 !default;
$small-font-size: 0.875em !default;
$sub-sup-font-size: 0.75em !default;
$text-muted: $gray-600 !default;
$initialism-font-size: $small-font-size !default;
$blockquote-margin-y: $spacer !default;
$blockquote-font-size: $font-size-base * 1.25 !default;
$blockquote-footer-color: $gray-600 !default;
$blockquote-footer-font-size: $small-font-size !default;
$hr-margin-y: $spacer * 2 !default;
$hr-margin-x: 0 !default;
$hr-color: $border-color !default;
$hr-height: $border-width !default;
$hr-opacity: 0.9 !default;
$legend-margin-bottom: 0.5rem !default;
$legend-font-size: 1.5rem !default;
$legend-font-weight: null !default;
$mark-padding: 0.2em !default;
$dt-font-weight: $font-weight-bold !default;
$nested-kbd-font-weight: $font-weight-bold !default;
$list-inline-padding: 0.3rem !default;
$mark-bg: #fcf8e3 !default;
//
// Icons
//
$icon-size: 1rem !default;
$icon-size-xs: 1rem !default;
$icon-size-sm: 1.2rem !default;
$icon-size-md: 2rem !default;
$icon-size-lg: 2rem !default;
$icon-size-xl: 3rem !default;
$icon-bg-level: -10 !default;
$icon-font-family: "inspiro-icons" !default;
$icon-font-weight: $font-weight-normal !default;
// Font Awesome
$icon-font-family-fontawesome: "Font Awesome 5 Free";
$icon-font-family-fontawesome-brands: "Font Awesome 5 Brands";
// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.
// scss-docs-start table-variables
$table-cell-padding-y: 1rem !default;
$table-cell-padding-x: 1rem !default;
$table-cell-padding-y-sm: 0.25rem !default;
$table-cell-padding-x-sm: 0.25rem !default;
$table-cell-vertical-align: middle !default;
$table-color: $body-color !default;
$table-bg: transparent !default;
$table-th-font-weight: $font-weight-bold !default;
$table-striped-color: $table-color !default;
$table-striped-bg-factor: 0.05 !default;
$table-striped-bg: rgba($gray-100, $table-striped-bg-factor) !default;
$table-active-color: $table-color !default;
$table-active-bg-factor: 1 !default;
$table-active-bg: rgba($gray-100, $table-active-bg-factor) !default;
$table-hover-color: $table-color !default;
$table-hover-bg-factor: 0.8 !default;
$table-hover-bg: rgba($gray-100, $table-hover-bg-factor) !default;
$table-border-factor: 0.1 !default;
$table-border-width: $border-width !default;
$table-border-color: $border-color !default;
$table-striped-order: odd !default;
$table-group-separator-color: $border-color !default;
$table-caption-color: $text-muted !default;
$table-bg-scale: -80% !default;
$table-head-color: $gray-300 !default; //chkd
$table-head-font-size: 0.9rem !default;
$table-head-font-weight: $font-weight-bold !default;
$table-head-text-transform: uppercase !default;
$table-head-bg-color: $gray-100 !default;
$table-head-padding-y: 1rem !default;
$table-head-padding-x: 1rem !default;
// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.
$input-btn-padding-y: 0.5rem !default;
$input-btn-padding-x: 1rem !default;
$input-btn-font-family: null !default;
$input-btn-font-size: $font-size-base !default;
$input-btn-line-height: $line-height-base !default;
$input-btn-focus-width: 0.2rem !default;
$input-btn-focus-color-opacity: 0.25 !default;
$input-btn-focus-color: none !default; //rgba($component-active-bg, .25) !default;
$input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width $input-btn-focus-color !default;
$input-btn-padding-y-xs: 0.125rem !default;
$input-btn-padding-x-xs: 0.25rem !default;
$input-btn-font-size-xs: $font-size-xs !default;
$input-btn-line-height-xs: $input-btn-line-height !default;
$input-btn-padding-y-sm: 0.35rem !default;
$input-btn-padding-x-sm: 0.8rem !default;
$input-btn-font-size-sm: $font-size-sm !default;
$input-btn-line-height-sm: $line-height-sm !default;
$input-btn-padding-y-lg: 0.5rem !default;
$input-btn-padding-x-lg: 1rem !default;
$input-btn-font-size-lg: $font-size-lg !default;
$input-btn-line-height-lg: $line-height-lg !default;
$input-btn-padding-y-xl: 1.5rem !default;
$input-btn-padding-x-xl: 2rem !default;
$input-btn-font-size-xl: $font-size-xl !default;
$input-btn-line-height-xl: $line-height-xl !default;
$input-btn-border-width: $border-width !default;
// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.
$btn-padding-y: $input-btn-padding-y !default;
$btn-padding-x: $input-btn-padding-x !default;
$btn-font-family: $input-btn-font-family !default;
$btn-font-size: $input-btn-font-size !default;
$btn-line-height: $input-btn-line-height !default;
$btn-white-space: null !default; // Set to `nowrap` to prevent text wrapping
$btn-padding-y-xs: $input-btn-padding-y-xs !default;
$btn-padding-x-xs: $input-btn-padding-x-xs !default;
$btn-font-size-xs: $input-btn-font-size-xs !default;
$btn-line-height-xs: $input-btn-line-height-xs !default;
$btn-padding-y-sm: $input-btn-padding-y-sm !default;
$btn-padding-x-sm: $input-btn-padding-x-sm !default;
$btn-font-size-sm: $input-btn-font-size-sm !default;
$btn-line-height-sm: $input-btn-line-height-sm !default;
$btn-padding-y-lg: $input-btn-padding-y-lg !default;
$btn-padding-x-lg: $input-btn-padding-x-lg !default;
$btn-font-size-lg: $input-btn-font-size-lg !default;
$btn-line-height-lg: $input-btn-line-height-lg !default;
$btn-padding-y-xl: $input-btn-padding-y-xl !default;
$btn-padding-x-xl: $input-btn-padding-x-xl !default;
$btn-font-size-xl: $input-btn-font-size-xl !default;
$btn-line-height-xl: $input-btn-line-height-xl !default;
$btn-border-width: $input-btn-border-width !default;
$btn-font-weight: $font-weight-semi-bold !default;
$btn-letter-spacing: $letter-spacing !default;
$btn-font-family: $font-family-base !default;
$btn-font-size: $font-size-base !default;
$btn-color: $white !default;
$btn-text-transform: none !default;
$btn-box-shadow: none !default;
$btn-focus-width: none !default; //$input-btn-focus-width !default;
$btn-focus-box-shadow: $input-btn-focus-box-shadow !default;
$btn-disabled-opacity: 0.65 !default;
$btn-active-box-shadow: none !default; //inset 0 3px 5px rgba($black, .125) !default;
$btn-link-color: $link-color !default;
$btn-link-hover-color: $link-hover-color !default;
$btn-link-disabled-color: $gray-600 !default;
// Allows for customizing button radius independently from global border radius
$btn-border-radius: $border-radius !default;
$btn-border-radius-sm: $border-radius-sm !default;
$btn-border-radius-lg: $border-radius !default;
$btn-border-radius-xs: $border-radius-xs !default;
$btn-border-radius-xl: $border-radius !default;
$btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;
$btn-soft-bg-scale: -50% !default;
$btn-soft-border-scale: $btn-soft-bg-scale !default;
$btn-soft-color-scale: 40% !default;
// Forms
$form-text-margin-top: 0.25rem !default;
$form-text-font-size: $font-size-sm !default;
$form-text-font-style: null !default;
$form-text-font-weight: null !default;
$form-text-color: $text-muted !default;
$form-label-margin-bottom: 0.5rem !default;
$form-label-font-size: $font-size-sm !default;
$form-label-font-style: null !default;
$form-label-font-weight: $font-weight-semi-bold !default;
$form-label-color: $gray-800 !default;
$form-label-text-transform: none !default;
$input-padding-y: $input-btn-padding-y !default;
$input-padding-x: $input-btn-padding-x !default;
$input-font-family: $input-btn-font-family !default;
$input-font-size: $input-btn-font-size !default;
$input-font-weight: $font-weight-base !default;
$input-line-height: $input-btn-line-height !default;
$input-padding-y-sm: $input-btn-padding-y-sm !default;
$input-padding-x-sm: $input-btn-padding-x-sm !default;
$input-font-size-sm: $input-btn-font-size-sm !default;
$input-line-height-sm: $input-btn-line-height-sm !default;
$input-padding-y-lg: $input-btn-padding-y-lg !default;
$input-padding-x-lg: $input-btn-padding-x-lg !default;
$input-font-size-lg: $input-btn-font-size-lg !default;
$input-line-height-lg: $input-btn-line-height-lg !default;
$input-padding-y-xl: $input-btn-padding-y-xl !default;
$input-padding-x-xl: $input-btn-padding-x-xl !default;
$input-font-size-xl: $input-btn-font-size-xl !default;
$input-line-height-xl: $input-btn-line-height-xl !default;
$input-bg: $white !default;
$input-disabled-bg: $gray-100 !default;
$input-disabled-border-color: none !default;
$input-color: $gray-900 !default;
$input-border-color: $border-color !default;
$input-border-width: $input-btn-border-width !default;
$input-box-shadow: none !default;
$input-border-radius: $border-radius !default;
$input-border-radius-sm: $border-radius-sm !default;
$input-border-radius-lg: $border-radius !default;
$input-border-radius-xl: $border-radius !default;
$input-focus-bg: $input-bg !default;
$input-focus-border-color: tint-color($component-active-bg, 50%) !default;
$input-focus-color: $input-color !default;
$input-focus-width: $input-btn-focus-width !default;
$input-focus-box-shadow: $input-btn-focus-box-shadow !default;
$input-placeholder-color: $gray-600 !default;
$input-plaintext-color: rgba($body-color, 0.8) !default;
$input-height-border: $input-border-width * 2 !default;
$input-height-inner: add(
  $input-line-height * 1em,
  $input-padding-y * 2
) !default;
$input-height-inner-half: add(
  $input-line-height * 0.5em,
  $input-padding-y
) !default;
$input-height-inner-quarter: add(
  $input-line-height * 0.25em,
  calc($input-padding-y / 2)
) !default;
$input-height: add(
  $input-line-height * 1em,
  add($input-padding-y * 2, $input-height-border, false)
) !default;
$input-height-sm: add(
  $input-line-height * 1em,
  add($input-padding-y-sm * 2, $input-height-border, false)
) !default;
$input-height-lg: add(
  $input-line-height * 1em,
  add($input-padding-y-lg * 2, $input-height-border, false)
) !default;
$input-transition: $transition-base !default;
$input-group-addon-padding-y: $input-padding-y !default;
$input-group-addon-padding-x: $input-padding-x !default;
$input-group-addon-font-weight: $input-font-weight !default;
$input-group-addon-color: $input-color !default;
$input-group-addon-bg: $gray-200 !default;
$input-group-addon-border-color: $input-border-color !default;
$form-check-input-width: 1.3em !default;
$form-check-min-height: $font-size-base * $line-height-base !default;
$form-check-padding-start: $form-check-input-width + 0.5em !default;
$form-check-margin-bottom: 0.125rem !default;
$form-check-label-color: null !default;
$form-check-label-cursor: pointer !default;
$form-check-transition: background-color 0.15s ease-in-out,
  background-position 0.15s ease-in-out, border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out !default;
$form-check-input-active-filter: brightness(90%) !default;
$form-check-input-bg: $body-bg !default;
$form-check-input-border: 1px solid rgba(0, 0, 0, 0.25) !default;
$form-check-input-border-radius: 0.25em !default;
$form-check-radio-border-radius: 50% !default;
$form-check-input-focus-border: $input-focus-border-color !default;
$form-check-input-focus-box-shadow: $input-btn-focus-box-shadow !default;
$form-check-input-checked-color: $component-active-color !default;
$form-check-input-checked-bg-color: $component-active-bg !default;
$form-check-input-checked-border-color: $form-check-input-checked-bg-color !default;
$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-checked-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/></svg>") !default;
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$form-check-input-checked-color}'/></svg>") !default;
$form-check-input-indeterminate-color: $component-active-color !default;
$form-check-input-indeterminate-bg-color: $component-active-bg !default;
$form-check-input-indeterminate-border-color: $form-check-input-indeterminate-bg-color !default;
$form-check-input-indeterminate-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-indeterminate-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/></svg>") !default;
$form-check-input-disabled-opacity: 0.5 !default;
$form-check-label-disabled-opacity: $form-check-input-disabled-opacity !default;
$form-check-btn-check-disabled-opacity: $btn-disabled-opacity !default;
$form-switch-color: rgba(0, 0, 0, 0.25) !default;
$form-switch-width: 2em !default;
$form-switch-padding-start: $form-switch-width + 0.5em !default;
$form-switch-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color}'/></svg>") !default;
$form-switch-border-radius: $form-switch-width !default;
$form-switch-focus-color: $input-focus-border-color !default;
$form-switch-focus-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-focus-color}'/></svg>") !default;
$form-switch-checked-color: $component-active-color !default;
$form-switch-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-checked-color}'/></svg>") !default;
$form-switch-checked-bg-position: right center !default;
$form-check-inline-margin-end: 1rem !default;
$form-select-padding-y: $input-padding-y !default;
$form-select-padding-x: $input-padding-x !default;
$form-select-font-family: $input-font-family !default;
$form-select-font-size: $input-font-size !default;
$form-select-indicator-padding: 1rem !default; // Extra padding to account for the presence of the background-image based indicator
$form-select-font-weight: $input-font-weight !default;
$form-select-line-height: $input-line-height !default;
$form-select-color: $input-color !default;
$form-select-disabled-color: $gray-600 !default;
$form-select-bg: $input-bg !default;
$form-select-disabled-bg: $gray-300 !default;
$form-select-disabled-border-color: $input-disabled-border-color !default;
$form-select-bg-position: right $form-select-padding-x center !default;
$form-select-bg-size: 16px 12px !default; // In pixels because image dimensions
$form-select-indicator-color: $gray-800 !default;
$form-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$form-select-indicator-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>") !default;
$form-select-feedback-icon-padding-end: add(
  1em * 0.75,
  (2 * $form-select-padding-y * 0.75) + $form-select-padding-x +
    $form-select-indicator-padding
) !default;
$form-select-feedback-icon-position: center right
  ($form-select-padding-x + $form-select-indicator-padding) !default;
$form-select-feedback-icon-size: $input-height-inner-half
  $input-height-inner-half !default;
$form-select-border-width: $input-border-width !default;
$form-select-border-color: $input-border-color !default;
$form-select-border-radius: calc($border-radius / 1.5);
$form-select-box-shadow: none !default;
$form-select-focus-border-color: $input-focus-border-color !default;
$form-select-focus-width: $input-focus-width !default;
$form-select-focus-box-shadow: 0 0 0 $form-select-focus-width
  $input-btn-focus-color !default;
$form-select-padding-y-sm: $input-padding-y-sm !default;
$form-select-padding-x-sm: $input-padding-x-sm !default;
$form-select-font-size-sm: $input-font-size-sm !default;
$form-select-padding-y-lg: $input-padding-y-lg !default;
$form-select-padding-x-lg: $input-padding-x-lg !default;
$form-select-font-size-lg: $input-font-size-lg !default;
$form-range-track-width: 100% !default;
$form-range-track-height: 0.5rem !default;
$form-range-track-cursor: pointer !default;
$form-range-track-bg: $gray-300 !default;
$form-range-track-border-radius: 1rem !default;
$form-range-track-box-shadow: none !default;
$form-range-thumb-width: 1rem !default;
$form-range-thumb-height: $form-range-thumb-width !default;
$form-range-thumb-bg: $component-active-bg !default;
$form-range-thumb-border: 0 !default;
$form-range-thumb-border-radius: 1rem !default;
$form-range-thumb-box-shadow: 0 0.1rem 0.25rem rgba($black, 0.1) !default;
$form-range-thumb-focus-box-shadow: 0 0 0 1px $body-bg, $input-focus-box-shadow !default;
$form-range-thumb-focus-box-shadow-width: $input-focus-width !default; // For focus box shadow issue in Edge
$form-range-thumb-active-bg: tint-color($component-active-bg, 70%) !default;
$form-range-thumb-disabled-bg: $gray-500 !default;
$form-range-thumb-transition: background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;
$form-file-button-color: $input-color !default;
$form-file-button-bg: $input-group-addon-bg !default;
$form-file-button-hover-bg: shade-color($form-file-button-bg, 5%) !default;
$form-floating-height: add(3.5rem, $input-height-border) !default;
$form-floating-padding-x: $input-padding-x !default;
$form-floating-padding-y: 1rem !default;
$form-floating-input-padding-t: 1.625rem !default;
$form-floating-input-padding-b: 0.625rem !default;
$form-floating-label-opacity: 0.65 !default;
$form-floating-label-transform: scale(0.85) translateY(-0.5rem)
  translateX(0.15rem) !default;
$form-floating-transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out !default;
// Form validation
$form-feedback-margin-top: $form-text-margin-top !default;
$form-feedback-font-size: $form-text-font-size !default;
$form-feedback-font-style: $form-text-font-style !default;
$form-feedback-valid-color: $success !default;
$form-feedback-invalid-color: $danger !default;
$form-feedback-icon-valid-color: $form-feedback-valid-color !default;
$form-feedback-icon-valid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>") !default;
$form-feedback-icon-invalid-color: $form-feedback-invalid-color !default;
$form-feedback-icon-invalid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='#{$form-feedback-icon-invalid-color}'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{$form-feedback-icon-invalid-color}' stroke='none'/></svg>") !default;
// scss-docs-start form-validation-states
$form-validation-states: (
  "valid": (
    "color": $form-feedback-valid-color,
    "icon": $form-feedback-icon-valid,
  ),
  "invalid": (
    "color": $form-feedback-invalid-color,
    "icon": $form-feedback-icon-invalid,
  ),
) !default;
// Navs
$nav-link-padding-y: 0.25rem !default;
$nav-link-padding-x: 1rem !default;
$nav-link-font-size: $font-size-base !default;
$nav-link-font-weight: null !default;
$nav-link-color: $link-color !default;
$nav-link-active-color: $body-color !default;
$nav-link-transition: color 0.15s ease-in-out,
  background-color 0.15s ease-in-out, border-color 0.15s ease-in-out !default;
$nav-link-disabled-color: $gray-600 !default;
$nav-tabs-border-color: $gray-300 !default;
$nav-tabs-border-width: $border-width !default;
$nav-tabs-border-radius: $border-radius !default;
$nav-tabs-link-hover-border-color: $gray-500 $gray-500 $nav-tabs-border-color !default;
$nav-tabs-link-active-color: $gray-700 !default;
$nav-tabs-link-active-bg: $body-bg !default;
$nav-tabs-link-active-border-color: $gray-500 $gray-500 $nav-tabs-link-active-bg !default;
$nav-pills-border-radius: $border-radius !default;
$nav-pills-link-active-color: $component-active-color !default;
$nav-pills-link-active-bg: $component-active-bg !default;
$nav-divider-color: $gray-300 !default;
$nav-divider-margin-y: calc($spacer / 2);
// Navbar
$navbar-padding-y: $spacer * 0.75 !default;
$navbar-padding-x: 0 !default;
//.6875rem 1.875rem
$navbar-nav-link-padding-x: 0.5rem !default;
$navbar-nav-link-padding-y: 1rem !default;
$navbar-nav-link-font-family: $font-family-base !default;
$navbar-nav-link-font-size: $font-size-base !default;
$navbar-nav-link-font-weight: $font-weight-semi-bold !default;
$navbar-nav-link-color: #7e8299 !default; //chkd $gray-900;
$navbar-nav-link-text-transform: none !default;
//Navbar Logo
$navbar-brand-font-size: $font-size-lg !default;
$navbar-brand-font-family: $font-family-base;
$nav-link-height: $font-size-base * $line-height-base + $nav-link-padding-y * 2 !default;
$navbar-brand-height: $navbar-brand-font-size * $line-height-base !default;
$navbar-brand-padding-y: calc(($nav-link-height - $navbar-brand-height) / 2);
$navbar-toggler-padding-y: 0.25rem !default;
$navbar-toggler-padding-x: 0.75rem !default;
$navbar-toggler-font-size: $font-size-lg !default;
$navbar-toggler-border-radius: $btn-border-radius !default;
$navbar-toggler-focus-width: $btn-focus-width !default;
$navbar-toggler-transition: box-shadow 0.15s ease-in-out !default;
$navbar-toggler-color: $navbar-nav-link-color !default;
$navbar-dark-color: rgba($white, 0.55) !default;
$navbar-dark-hover-color: rgba($white, 0.75) !default;
$navbar-dark-active-color: $white !default;
$navbar-dark-disabled-color: rgba($white, 0.25) !default;
$navbar-dark-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-dark-toggler-border-color: rgba($white, 0.1) !default;
$navbar-light-color: rgba($black, 0.55) !default;
$navbar-light-hover-color: rgba($black, 0.7) !default;
$navbar-light-active-color: rgba($black, 0.9) !default;
$navbar-light-disabled-color: rgba($black, 0.3) !default;
$navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-light-toggler-border-color: rgba($black, 0.1) !default;
$navbar-light-brand-color: $navbar-light-active-color !default;
$navbar-light-brand-hover-color: $navbar-light-active-color !default;
$navbar-dark-brand-color: $navbar-dark-active-color !default;
$navbar-dark-brand-hover-color: $navbar-dark-active-color !default;
// Dropdowns
//
// Dropdown menu container and contents.
$dropdown-min-width: 11rem !default;
$dropdown-min-width-sm: 12rem !default;
$dropdown-min-width-md: 22rem !default;
$dropdown-min-width-lg: 32rem !default;
$dropdown-min-width-xl: 46rem !default;
$dropdown-padding-x: 0 !default;
$dropdown-padding-y: 1rem !default;
$dropdown-spacer: 0.125rem !default;
$dropdown-font-size: $font-size-sm !default;
$dropdown-color: $body-color !default;
$dropdown-bg: $white !default;
$dropdown-border-color: $border-color !default;
$dropdown-border-radius: $border-radius !default;
$dropdown-border-width: $border-width !default;
$dropdown-inner-border-radius: subtract(
  $dropdown-border-radius,
  $dropdown-border-width
) !default;
$dropdown-divider-bg: $gray-300 !default;
$dropdown-divider-margin-y: $nav-divider-margin-y !default;
$dropdown-box-shadow: 0 0.625rem 2.5rem 0.625rem rgba(140, 152, 164, 0.175) !default;
$dropdown-link-font-size: $font-size-sm !default;
$dropdown-link-font-weight: $font-weight-normal !default;
$dropdown-link-color: $gray-900 !default;
$dropdown-link-hover-color: $gray-900 !default;
$dropdown-link-hover-bg: $gray-300 !default;
$dropdown-link-active-color: none !default;
$dropdown-link-active-bg: none !default;
$dropdown-link-disabled-color: $gray-600 !default;
$dropdown-item-padding-y: 0.5rem !default;
$dropdown-item-padding-x: 1.5rem !default;
$dropdown-header-color: $gray-600 !default;
$dropdown-header-padding: $dropdown-padding-y $dropdown-item-padding-x !default;
$dropdown-dark-color: $gray-300 !default;
$dropdown-dark-bg: $gray-800 !default;
$dropdown-dark-border-color: $dropdown-border-color !default;
$dropdown-dark-divider-bg: $dropdown-divider-bg !default;
$dropdown-dark-box-shadow: null !default;
$dropdown-dark-link-color: $dropdown-dark-color !default;
$dropdown-dark-link-hover-color: $white !default;
$dropdown-dark-link-hover-bg: rgba($white, 0.15) !default;
$dropdown-dark-link-active-color: $dropdown-link-active-color !default;
$dropdown-dark-link-active-bg: $dropdown-link-active-bg !default;
$dropdown-dark-link-disabled-color: $gray-500 !default;
$dropdown-dark-header-color: $gray-500 !default;
// Sidebar menu
//
// Sidebar menucontainer and contents.
$sidebar-dropdown-item-padding-y: $dropdown-item-padding-y !default;
$sidebar-dropdown-item-padding-x: $dropdown-item-padding-x !default;
$sidebar-dropdown-link-color: $dropdown-link-color !default;
$sidebar-dropdown-font-size: $dropdown-font-size !default;
// Navbar dropdown
$navbar-dropdown-font-family: $navbar-nav-link-font-family !default;
$navbar-dropdown-padding-y: $dropdown-padding-y !default;
$navbar-dropdown-link-color: rgba($black, 0.8) !default;
$navbar-dropdown-link-hover-color: $navbar-dropdown-link-color !default;
$navbar-dropdown-link-hover-bg: $gray-100 !default;
$navbar-dropdown-link-active-color: $navbar-dropdown-link-hover-color !default;
$navbar-dropdown-link-active-bg: transparent !default;
$navbar-dropdown-item-padding-y: 0.5rem !default;
$navbar-dropdown-item-padding-x: 1.5rem !default;
$navbar-dropdown-heading-color: lighten($gray-800, 7%) !default;
$navbar-dropdown-min-width: 18rem !default;
// Mega Menu
$megamenu-padding-y: $navbar-dropdown-padding-y; //mine
$megamenu-padding-x: 0.5rem; //mine
//  Navbar collapse
$navbar-collapse-bg: $white !default;
// Pagination
$pagination-padding-y: 0.5rem !default;
$pagination-padding-x: 0.75rem !default;
$pagination-padding-y-sm: 0.25rem !default;
$pagination-padding-x-sm: 0.5rem !default;
$pagination-padding-y-lg: 0.75rem !default;
$pagination-padding-x-lg: 1.5rem !default;
//$pagination-line-height:            1.25 !default;
$pagination-color: $link-color !default;
$pagination-bg: $white !default;
$pagination-border-width: $border-width !default;
$pagination-border-radius: $border-radius !default;
$pagination-margin-start: -$pagination-border-width !default;
$pagination-border-color: $gray-300 !default;
$pagination-focus-color: $link-hover-color !default;
$pagination-focus-bg: $gray-300 !default;
$pagination-focus-box-shadow: $input-btn-focus-box-shadow !default;
$pagination-focus-outline: 0 !default;
$pagination-hover-color: $link-hover-color !default;
$pagination-hover-bg: $gray-300 !default;
$pagination-hover-border-color: $gray-300 !default;
$pagination-active-color: $component-active-color !default;
$pagination-active-bg: $component-active-bg !default;
$pagination-active-border-color: $pagination-active-bg !default;
$pagination-disabled-color: $gray-600 !default;
$pagination-disabled-bg: $white !default;
$pagination-disabled-border-color: $gray-300 !default;
$pagination-transition: color 0.15s ease-in-out,
  background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out !default;
$pagination-font-size: $font-size-base !default;
$pagination-border-radius: $border-radius-sm !default;
// Cards
$card-spacer-y: 1.5rem !default;
$card-spacer-x: 1.5rem !default;
$card-title-spacer-y: calc($spacer / 2);
$card-border-width: $border-width !default;
$card-border-radius: $border-radius !default;
$card-border-color: $border-color !default;
$card-inner-border-radius: subtract(
  $card-border-radius,
  $card-border-width
) !default;
$card-cap-padding-y: $card-spacer-y !default;
$card-cap-padding-x: $card-spacer-x !default;
$card-cap-bg: $white !default;
$card-cap-color: null !default;
$card-height: null !default;
$card-color: null !default;
$card-bg: $white !default;
$card-box-shadow: 0 0 1.25rem rgba(31, 45, 61, 0.05) !default;

//chkd $box-shadow !default;
$card-title-font-weight: $font-weight-semi-bold !default;
$card-img-overlay-padding: $spacer !default;
$card-group-margin: 0 !default;
$card-header-font-size: 1.275rem !default;

// $card-columns-count:                3 !default;
// $card-columns-gap:                  1.25rem !default;
// $card-columns-margin:               $card-spacer-y !default;

// $card-header-padding-y:             $card-spacer-y !default;
// $card-header-padding-x:             $card-spacer-x !default;

// $card-title-font-weight:       $font-weight-semi-bold !default;

// Accordion
// $accordion-padding-y:                     1rem !default;
// $accordion-padding-x:                     1.25rem !default;
// $accordion-color:                         $body-color !default;
// $accordion-bg:                            transparent !default;
// $accordion-border-width:                  $border-width !default;
// $accordion-border-color:                  rgba($black, .125) !default;
// $accordion-border-radius:                 $border-radius !default;
// $accordion-body-padding-y:                $accordion-padding-y !default;
// $accordion-body-padding-x:                $accordion-padding-x !default;
// $accordion-button-padding-y:              $accordion-padding-y !default;
// $accordion-button-padding-x:              $accordion-padding-x !default;
// $accordion-button-color:                  $accordion-color !default;
// $accordion-button-bg:                     $accordion-bg !default;
// $accordion-transition:                    $btn-transition, border-radius .15s ease !default;
// $accordion-button-active-bg:              tint-color($component-active-bg, 90%) !default;
// $accordion-button-active-color:           shade-color($primary, 10%) !default;
// $accordion-button-focus-border-color:     $input-focus-border-color !default;
// $accordion-button-focus-box-shadow:       $btn-focus-box-shadow !default;
// $accordion-icon-width:                    1.25rem !default;
// $accordion-icon-color:                    $accordion-color !default;
// $accordion-icon-active-color:             $accordion-button-active-color !default;
// $accordion-icon-transition:               transform .2s ease-in-out !default;
// $accordion-icon-transform:                rotate(180deg) !default;
// $accordion-button-icon:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;
// $accordion-button-active-icon:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-active-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;
// Tooltips
$tooltip-font-size: $font-size-sm !default;
$tooltip-max-width: 200px !default;
$tooltip-color: $white !default;
$tooltip-bg: $black !default;
$tooltip-border-radius: $border-radius !default;
$tooltip-opacity: 0.9 !default;
$tooltip-padding-y: calc($spacer / 4);
$tooltip-padding-x: calc($spacer / 2);
$tooltip-margin: 0 !default;
$tooltip-arrow-width: 0.8rem !default;
$tooltip-arrow-height: 0.4rem !default;
$tooltip-arrow-color: $tooltip-bg !default;
// Form tooltips must come after regular tooltips
$form-feedback-tooltip-padding-y: $tooltip-padding-y !default;
$form-feedback-tooltip-padding-x: $tooltip-padding-x !default;
$form-feedback-tooltip-font-size: $tooltip-font-size !default;
$form-feedback-tooltip-line-height: null !default;
$form-feedback-tooltip-opacity: $tooltip-opacity !default;
$form-feedback-tooltip-border-radius: $tooltip-border-radius !default;
// Popovers
$popover-font-size: $font-size-sm !default;
$popover-bg: $white !default;
$popover-max-width: 276px !default;
$popover-border-width: $border-width !default;
$popover-border-color: rgba($black, 0.2) !default;
$popover-border-radius: $border-radius !default;
$popover-inner-border-radius: subtract(
  $popover-border-radius,
  $popover-border-width
) !default;
$popover-box-shadow: $box-shadow !default;
$popover-header-bg: shade-color($popover-bg, 6%) !default;
$popover-header-color: $headings-color !default;
$popover-header-padding-y: 0.5rem !default;
$popover-header-padding-x: $spacer !default;
$popover-body-color: $body-color !default;
$popover-body-padding-y: $spacer !default;
$popover-body-padding-x: $spacer !default;
$popover-arrow-width: 1rem !default;
$popover-arrow-height: 0.5rem !default;
$popover-arrow-color: $popover-bg !default;
$popover-arrow-outer-color: fade-in($popover-border-color, 0.05) !default;
// Toasts
$toast-max-width: 350px !default;
$toast-padding-x: 0.75rem !default;
$toast-padding-y: 0.5rem !default;
$toast-font-size: 0.875rem !default;
$toast-color: null !default;
$toast-background-color: rgba($white, 0.85) !default;
$toast-border-width: 1px !default;
$toast-border-color: rgba(0, 0, 0, 0.1) !default;
$toast-border-radius: $border-radius !default;
$toast-box-shadow: $box-shadow !default;
$toast-spacing: $container-padding-x !default;
$toast-header-color: $gray-600 !default;
$toast-header-background-color: rgba($white, 0.85) !default;
$toast-header-border-color: rgba(0, 0, 0, 0.05) !default;

// Badges

//$badge-font-size: 80% !default;

$badge-font-weight: 600 !default;
$badge-color: $white !default;

//$badge-padding-y:                   .3125rem !default;
//$badge-padding-x:                   .4375rem !default;

$badge-border-radius: $border-radius-sm !default;
$badge-soft-bg-scale: -85% !default;
$badge-soft-border-scale: $badge-soft-bg-scale !default;
$badge-soft-color-scale: -10% !default;
//Modal aside //mine
$modal-aside-width: 300px !default;
$modal-aside-transition: $transition-base !default;
//chkd temp
$modal-aside-width-collapsed: 80px !default;
// Modals
// Padding applied to the modal body
$modal-inner-padding: $spacer * 1.5 !default;
// Margin between elements in footer, must be lower than or equal to 2 * $modal-inner-padding
$modal-footer-margin-between: 0.5rem !default;
$modal-dialog-margin: 0.5rem !default;
$modal-dialog-margin-y-sm-up: 1.75rem !default;
$modal-title-line-height: $line-height-base !default;
$modal-content-color: null !default;
$modal-content-bg: $white !default;
$modal-content-border-color: $border-color !default;
$modal-content-border-width: $border-width !default;
$modal-content-border-radius: $border-radius !default;
$modal-content-inner-border-radius: subtract(
  $modal-content-border-radius,
  $modal-content-border-width
) !default;
$modal-content-box-shadow-xs: $box-shadow !default;
$modal-content-box-shadow-sm-up: $box-shadow !default;
$modal-backdrop-bg: $black !default;
$modal-backdrop-opacity: 0.5 !default;
$modal-header-border-color: $border-color !default;
$modal-footer-border-color: $modal-header-border-color !default;
$modal-header-border-width: 0 !default;
$modal-footer-border-width: $border-width !default;
$modal-header-padding-y: $modal-inner-padding !default;
$modal-header-padding-x: $modal-inner-padding !default;
$modal-header-padding: $modal-header-padding-y $modal-header-padding-x 0
  $modal-header-padding-x !default; // Keep this for backwards compatibility
$modal-sm: 300px !default;
$modal-md: 500px !default;
$modal-lg: 800px !default;
$modal-xl: 1140px !default;
$modal-fade-transform: translate(0, -50px) !default;
$modal-show-transform: none !default;
$modal-transition: transform 0.3s ease-out !default;
$modal-scale-transform: scale(1.02) !default;
// Alerts
//
// Define alert colors, border radius, and padding.
$alert-padding-y: $spacer !default;
$alert-padding-x: $spacer !default;
$alert-margin-bottom: 1rem !default;
$alert-border-radius: $border-radius !default;
$alert-link-font-weight: $font-weight-bold !default;
$alert-border-width: $border-width !default;
$alert-bg-scale: -80% !default;
$alert-border-scale: -70% !default;
$alert-color-scale: 40% !default;
$alert-dismissible-padding-r: $alert-padding-x * 3 !default; // 3x covers width of x plus default padding on either side
// Progress bars
$progress-height: 1rem !default;
$progress-font-size: $font-size-base * 0.75 !default;
$progress-bg: $gray-300 !default;
$progress-border-radius: $border-radius !default;
$progress-box-shadow: none !default;
$progress-bar-color: $white !default;
$progress-bar-bg: $primary !default;
$progress-bar-animation-timing: 1s linear infinite !default;
$progress-bar-transition: width 0.6s ease !default;
// List group
$list-group-color: null !default;
$list-group-bg: $white !default;
$list-group-border-color: $border-color !default;
$list-group-border-width: $border-width !default;
$list-group-border-radius: $border-radius !default;
$list-group-item-padding-y: $spacer * 1.5 !default;
$list-group-item-padding-x: $spacer * 1.5 !default;
$list-group-item-bg-scale: -80% !default;
$list-group-item-color-scale: 40% !default;
$list-group-hover-bg: $gray-100 !default;
$list-group-active-color: $component-active-color !default;
$list-group-active-bg: $component-active-bg !default;
$list-group-active-border-color: $list-group-active-bg !default;
$list-group-disabled-color: $gray-600 !default;
$list-group-disabled-bg: $list-group-bg !default;
$list-group-action-color: $gray-300 !default;
$list-group-action-hover-color: $list-group-action-color !default;
$list-group-action-active-color: $body-color !default;
$list-group-action-active-bg: $gray-300 !default;
// Image thumbnails
$thumbnail-padding: 0.25rem !default;
$thumbnail-bg: $body-bg !default;
$thumbnail-border-width: $border-width !default;
$thumbnail-border-color: $gray-300 !default;
$thumbnail-border-radius: $border-radius !default;
$thumbnail-box-shadow: $box-shadow-sm !default;
// Figures
$figure-caption-font-size: $small-font-size !default;
$figure-caption-color: $gray-600 !default;
// Breadcrumbs
$breadcrumb-font-size: $font-size-sm !default;
$breadcrumb-padding-y: 0 !default;
$breadcrumb-padding-x: 0 !default;
$breadcrumb-item-padding: 0.5rem !default;
$breadcrumb-margin-bottom: 1rem !default;
$breadcrumb-bg: transpranet !default;
$breadcrumb-divider-color: $gray-600 !default;
$breadcrumb-active-color: $gray-600 !default;
$breadcrumb-divider: "\2022" !default;
$breadcrumb-divider-flipped: $breadcrumb-divider !default;
$breadcrumb-border-radius: null !default;
$breadcrumb-item-color: $gray-500 !default;
$breadcrumb-item-font-weight: $font-weight-semi-bold !default;
$breadcrumb-item-color-hover-active: $link-color !default;
// Close
$btn-close-width: 0.6em !default;
$btn-close-height: $btn-close-width !default;
$btn-close-padding-x: 0.3em !default;
$btn-close-padding-y: $btn-close-padding-x !default;
$btn-close-color: $white !default;
$btn-close-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$btn-close-color}'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>") !default;
$btn-close-focus-shadow: $input-btn-focus-box-shadow !default;
$btn-close-opacity: 0.6 !default;
$btn-close-hover-opacity: 0.75 !default;
$btn-close-focus-opacity: 1 !default;
$btn-close-disabled-opacity: 0.25 !default;
$btn-close-white-filter: invert(1) grayscale(100%) brightness(200%) !default;
// Sections
$section-padding-y: $spacer * 6 !default;
$section-padding-x: 0 !default;
// Footer
$footer-bg: $gray-200 !default; //mine
// Layout  chkd
$layout-boxed-width: 1200px;
$layout-modern-width: 1500px;
$layout-modern-max-width: 1500px;
// Header
$header-height: 80px;
$header-line-height: 80px;
// Page Titile
$page-title-font: $font-family-base;
$page-title-background: $gray-100;
// Sidebar chkd
$sidebar-background: $gray-300;
//Dashboard
$dashboard-aside-width: 23rem !default;
$dashboard-aside-bg: $white !default;
$dashboard-aside-border-color: $border-color !default;
$dashboard-aside-border-width: $border-width !default;
$dashboard-container-padding-y: 0 !default;
$dashboard-container-padding-x: $grid-gutter-width !default;
$dashboard-padding-y: 1.75rem !default;
$dashboard-padding-x: 0 !default;
$gutters: $spacers !default;
// Container padding
$container-padding-x: $grid-gutter-width / 2 !default;
// Vertical navbar
$navbar-vertical-box-shadow: none !important;
$navbar-vertical-width: 0 !default;
$navbar-vertical-open-width: 250px !default;
$navbar-vertical-padding-x: 1.5rem !default;
$navbar-vertical-nav-link-color: $navbar-nav-link-color !default;
$navbar-vertical-nav-link-hover-color: $link-color !default;
$navbar-vertical-nav-link-padding-x: 2rem !default;
$navbar-vertical-nav-link-padding-y: 0.75rem !default;
$navbar-vertical-dropdown-item-font-size: $navbar-nav-link-font-size !default;
$navbar-vertical-border-width: 1px !default;
$navbar-vertical-border-style: solid !default;
$navbar-vertical-brand-wrapper-padding: $navbar-vertical-nav-link-padding-x !default;
$navbar-vertical-brand-font-family: $navbar-brand-font-family !default;
$navbar-vertical-brand-height: $navbar-brand-height !default;
