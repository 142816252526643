section {
    position: relative;
    width: 100%;
    z-index: 1;
    background-color: $white;

    .page-content {
        padding: 0;
    }

    /*&:first-child {
        padding-top: 80px;
    }*/
}


//chkd
@include media-breakpoint-down(md) {
    section {
        padding: 40px 0;
    }
}

@include media-breakpoint-down(lg) {
    .container {
        padding: 0 30px;
    }
}
