﻿.image-tiles-section {
    padding-top: 60px 0;

    .image-tiles-heading {
        & > h2 {
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;
            margin-bottom: 30px;
            color: $gray-500;
        }
    }

    @media screen and (min-width: 1024px) {
        padding: 40px 0 80px;
    }
}
