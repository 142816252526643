﻿.single-image {
    padding: 60px 0;

    h1,
    h2 {
        @include text-props(20px, 600, 24px, $gray-500);
        letter-spacing: 0;
        margin-bottom: 15px;
    }

    h2 {
        @include text-props(20px, 600, 24px, $gray-500);
        letter-spacing: 0;
        margin-bottom: 15px;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    @media screen and (min-width: 1024px) {
        padding: 80px 0;

        h1, 
        h2 {
            margin-bottom: 40px;
        }

        img {
            max-width: 50%;
            height: auto;
        }
    }
}
