﻿.contact-section {
  padding: 60px 0;

  h2 {
    @include text-props(20px, 600, 24px, $purple);
  }

  p {
    @include text-props(14px, 400, 17px, $gray-500);

    &.address {
      padding-left: 25px;
      position: relative;

      &:before {
        content: "";
        display: block;
        background-image: url('../../images/rna/address.png');
        background-image: url('../../images/rna/address.svg');
        background-repeat: no-repeat;
        height: 18px;
        width: 15px;
        position: absolute;
        left: 0;
      }
    }
  }

  a {
    @include text-props(14px, 400, 17px, $gray-500);

    &:hover {
      text-decoration: underline;
    }
  }

  .contact-us-form {

    label {
      @include text-props(14px, 600, 17px, $gray-400);

      .field-required {
        color: $red;
      }
    }

    .input {
      border: solid 1px $gray-400-blur-20;
    }

    fieldset {
      margin: 0 -15px;
    }

    .umbraco-forms-container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      & > div {
        flex: 0 0 100%;
        padding: 0 15px;
      }
    }

    .umbraco-forms-navigation {
      & > div {
        display: flex;
      }
    }

    .field-validation-error {
      color: $red;
    }

    @media (min-width: 1024px) {
      .umbraco-forms-container > div {
        flex: auto;
      }
    }
  }

  .map-details {
    height: 100%;
  }

  .map-container {
    position: relative;
    max-height: 425px;
    height: 100%;
    padding-bottom: 100%;
  }

  .map-frame {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
  }


  @media screen and (min-width: 768px) {

    .map-container {
      padding-bottom: 62.5%;
    }
  }

  @media screen and (min-width: 1024px) {
    padding: 80px 0;

    .map-container {
      padding-bottom: 68%;
    }
  }
}
