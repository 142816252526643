﻿.text-with-large-image-section {
  padding-top: 60px;
  padding-bottom: 30px;

  h2 {
    color: $purple;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 15px;
  }

  p {
    color: $gray-400;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 15px;
  }

  img {
    width: 100%;
    height: auto;
  }

  div.col-lg-6 {
    padding: 0 15px;
  }

  div.col-12 {

    img {
      max-width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
  }


  @media screen and (min-width: 1200px) {
    padding-top: 80px;
    padding-bottom: 40px;
  }
}
