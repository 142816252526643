/* ----------------------------------------------------------------
    FOOTER
-----------------------------------------------------------------*/

#footer {
    display: block;
    position: relative;
    background-color: $footer-bg;
    font-size: 14px;
    line-height: 32px;
    font-weight: 400;
    font-family: $headings-font-family;

    .footer-item {
        padding-left: 15px;
        padding-right: 15px;
        width: 100%;
        flex: 0 0 100%;

        &:last-child {
            .widget {
                margin-bottom: 0;

                ul {
                    margin-bottom: 0;
                }
            }
        }
    }

    a:not(.btn):not(:hover) {
        color: $gray-400;
    }

    p {
        font-family: $headings-font-family;
        color: $gray-400;
    }

    a:not(.btn) {
        font-weight: 400;
    }

    .footer-content {
        padding: 40px 0 40px 0;
    }

    .footer-address {
        margin-bottom: 33px;

        p {
            padding-left: 25px;
            position: relative;
            margin-bottom: 15px;
            @include text-props(14px, 400, 17px);

            &:before {
                background-repeat: no-repeat;
                background-size: contain;
                content: "";
                display: block;
                height: 18px;
                left: 0;
                position: absolute;
                width: 15px;
            }

            &.address:before {
                background-image: url(../../images/rna/address.png);
                background-image: url(../../images/rna/address.svg);
            }

            &.contact-number:before {
                background-image: url(../../images/rna/ico_phone.png);
                background-image: url(../../images/rna/ico_phone.svg);
            }
        }
    }

    .widget {
        .widget-title,
        h4 {
            font-size: 14px;
            letter-spacing: 1px;
            font-weight: 600;
            color: $gray-500;
            /* ul {
            li {
                a {
                    font-family: $headings-font-family;
                    font-size: 15px;
                    line-height: 32px;
                    color: #999;
                }
            }
        } */
        }

        .widget-title.widget-title-img {
            margin-bottom: 20px;
        }

        .widget-title {
            margin-bottom: 20px;

            a {
                @include text-props(14px, 600, 17px, $gray-400);

                &[href]:hover {
                    color: $gray-400;
                    text-decoration: underline;
                }
            }
        }

        ul {
            padding-left: 0;

            &.list li {
                line-height: 22px;
                padding-top: 5px;
                padding-bottom: 5px;

                a {
                    color: $gray-500;
                    word-break: break-word;
                    text-transform: capitalize;

                    &:hover,
                    &:focus,
                    &:focus-within {
                        color: $gray-500;
                        text-decoration: underline;
                    }
                }
            }
        }

        p {
            @include text-props(14px, 400, 17px)
        }

        .socials {
            width: 40px;
            height: 40px;
            background-position: -100px;
            background-size: 40px 40px;
            background-repeat: no-repeat;
            display: block;

            &:hover {
                background-position: 0;

                img {
                    visibility: hidden;
                }
            }
        }
    }

    .copyright-content {
        min-height: 80px;
        padding: 30px 0;
        background-color: darken($footer-bg, 3%);
        font-size: 13px;
    }
    //chkd

    .logo {
        margin-bottom: 54px;
        margin-top: 12px;
        max-width: 80px;
    }
    //chkd
    .footer-logo {
        border-right: 1px solid #333;
        margin-bottom: 20px;
        margin-right: 18px;
        padding-right: 16px;
    }

    .copyright-text {
        @include text-props(12px, 400, 23px);
        text-align: center;
        text-transform: capitalize;
        margin-bottom: 30px;
    }

    .copyright-links {
        text-align: center;

        > a {
            @include text-props(12px, 400, 23px);
            padding: 0 10px;
            position: relative;

            &:hover {
                color: $gray-400;
                text-decoration: underline;
            }

            &:nth-child(2):before {
                content: "";
                position: absolute;
                border-right: solid 1px $gray-500;
                width: 1px;
                left: 0;
                top: 0;
                bottom: 0;
            }
        }
    }

    .subscribe-form-container {
        h2 {
            @include text-props(14px, 600, 17px, $gray-400);
            margin-bottom: 6px;
        }

        .mc-field-group {
            display: flex;
            flex-wrap: wrap;

            div.mce_inline_error {
                flex: 100%;
            }
        }

        input[type=text],
        input[type=email] {
            outline: 0;
            border-radius: 5px;
            border: solid 1px $gray-500;
            padding: 5px 20px;
            flex: 1 0 auto;
        }

        input[type=text].no-right-border,
        input[type=email].no-right-border {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        .submit-newsletter {
            margin-bottom: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            position: relative;
            height: 44px;
            width: 44px;

            &:after {
                content: "";
                display: block;
                position: absolute;
                background-image: url(../../images/submit-newsletter.png);
                background-image: url(../../images/submit-newsletter.svg);
                background-repeat: no-repeat;
                background-size: contain !important;
                height: 15px;
                width: 17px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 1;
            }
        }
    }

    .scroll-to-container {
        margin-right: -20px;
    }

    @media screen and (min-width: 810px) {

        .footer-item {
            padding-left: 15px;
            padding-right: 15px;
            width: 50%;
            flex: 0 0 50%;

            &:last-child {
                .widget {
                    margin-bottom: 0;

                    ul {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    @media screen and (min-width: 1024px) {

        .footer-content {
            padding: 50px 0 50px 0;
        }

        .footer-address a:hover {
            text-decoration: underline;
        }

        .footer-item {
            padding-left: 7px;
            padding-right: 7px;
            width: 20%;
            flex: 0 0 20%;
        }

        .copyright-links {
            text-align: right;
        }

        .copyright-text {
            text-align: left;
            margin-bottom: 0;
        }

        .scroll-to-container {
            margin-right: -40px;
        }
    }
}

.dark #footer,
#footer.inverted,
#footer.dark {
    // Dark Version 
    background-color: #181818;
    p {
        color: #999;
    }
    a:not(.btn) {
        font-weight: 400;
    }
    a:not(.btn):not(:hover) {
        color: #999;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: #fff;
    }
    .widget .widget-title,
    .widget h4 {
        color: #fff;
    }
    .copyright-content {
        background-color: #1E1E1E;
    }
}
