 /* ----------------------------------------------------------------------
    Page title
-------------------------------------------------------------------------*/
#page-title {
    clear: both;
    /*padding: 80px 0;*/
    padding: 200px 0;
    background-color: $page-title-background;
    position: relative;

    .page-title {
        text-align: center;
        padding: 0;

        > h1 {
            font-family: $page-title-font;
            @include text-props(48px, 600, 58px, $white);
        }

        > span {
            font-weight: 400;
            font-size: 14px;
        }
    }

    .breadcrumb {
        text-align: center;
        margin-bottom: 0;
        padding: 0;
    }
    &[data-bg-parallax] {
        .page-title {
            > h1 {
                color: $white;
            }

            > span {
                color: $white;
            }
        }

        .breadcrumb {
            ul {
                li {
                    + li:before {
                        color: $white;
                    }

                    a {
                        color: $white;
                    }
                }
            }
        }
    }

    &[data-bg-parallax],
    &[data-bg-video] {
        background-color: $dark;
    }
}



 #header {
     &[data-transparent="true"] {
         +#page-title {
             top: -80px; //- ($header-height + 40);
             margin-bottom: -80px; //- ($header-height + 40);
             position: relative;
         }
     }
 }

@include media-breakpoint-down(lg) {
    #page-title {
        .page-title {
            > h1 {
                @include text-props(30px, 600, 38px, $white)
            }
        }
    }

    #header {
        &[data-transparent="true"] {
            + #page-title {
                /*top: - ($header-height + 40);
                margin-bottom: - ($header-height + 44);
                padding: ($header-height + $header-height) 0 $header-height 0;*/ 
                margin-bottom: -80px;
                padding: 160px 0 132px;
                top: -80px;
            }
        }
    }
}