﻿.media-pages {

    a {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: $gray-500;
        text-decoration: underline;

        &:hover,
        &:active,
        &:visited,
        &:focus,
        &:focus-within {
            color: $gray-500;
            text-decoration: underline;
        }
    }

    table.media-releases {
        width: 100%;

        thead {
            border-bottom: solid 1px #7773;
        }
    }

    @media screen and (min-width: 1024px) {
        a {
            text-decoration: none;
        }
    }
}

.media-releases-section {
    h2 {
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        color: $purple;
    }

    table.media-releases {

        .date-col {
            width: 28%;
        }

        .release-col {
            width: 72%;
        }
    }
}

.media-archives-section {
    padding: 60px 0;

    h2 {
        @include text-props(20px, 600, 24px, $gray-400)
    }

    h3 {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: $purple;
    }

    table.media-releases {

        thead {
            margin-bottom: 5px;
            padding-bottom: 5px;
            @include text-props(14px, 600, 17px);

            .date-col,
            .release-col {
                padding: 6px 0;
            }
        }

        tbody tr {

            td {
                padding-top: 7px;
                padding-bottom: 8px;
            }

            &:first-child {
                td {
                    padding-top: 15px;
                }
            }

            &:last-child {

                td {
                    padding-bottom: 15px;
                }
            }
        }

        td {
            display: table-cell;
            /*padding-top: 5px;
            padding-bottom: 5px;*/
            @include text-props(14px, 400, 17px);
        }

        .date-col {
            width: 28%;
        }

        .release-col {
            width: 72%;
        }
    }

    @media screen and (min-width: 1024px) {
        padding: 80px 0;
    }
}

.media-rows-section {
    padding: 80px 0;

    &:not(:first-child) {
        padding: 40px 0;
    }

    &:last-child {
        padding: 80px 0;
    }

    h1, h2 {
        @include text-props(20px, 600, 24px, $gray-500)
    }

    table.media-releases {

        thead {
            margin-bottom: 5px;
            padding-bottom: 5px;

            th {
                @include text-props(14px, 600, 17px);
                padding: 6px 0;
            }
        }

        tbody {
            padding: 8px 0;

            tr {
                td {
                    display: table-cell;
                    padding-top: 7px;
                    padding-bottom: 8px;
                }

                &:first-child {
                    td {
                        padding-top: 15px;
                    }
                }

                &:last-child {

                    td {
                        padding-bottom: 15px;
                    }
                }
            }
        }

        .date-col {
            width: 28%;
        }

        .release-col {
            width: 72%;
        }
    }

    @media screen and (min-width: 1024px) {
        table.media-releases {

            td {
                padding-left: 10px;
                padding-right: 10px;
            }
        }
    }
}

.media-gallery-list-section {
    padding: 60px 0 !important;


    h1, h2 {
        @include text-props(20px, 600, 24px);
        color: $gray-400;
    }


    @media screen and (min-width: 1024px) {
        padding: 80px 0 !important;
    }
}

.image-gallery-section {
    padding: 10px 0;

    h1 {
        @include text-props(20px, 600, 24px);
        color: $gray-400;
    }

    @media screen and (min-width: 1024px) {
        padding: 80px 0;
        
        .grid-item .grid-image img {
            height: 200px;
            width: 100%;
            object-fit: cover;
        }
    }
}

.media-coverage-section {
    padding: 60px 0;

    h2 {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: $gray-400;
    }

    @media screen and (min-width: 1024px) {
        padding: 80px 0;
    }
}
