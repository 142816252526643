﻿.regeneration-timeline-section {
    padding: 60px 0;

    h2 {
        text-transform: uppercase;
        @include text-props(20px, 600, 24px, $purple);
    }

    p {
        @include text-props(14px, 400, 17px, $gray-500);
        margin-bottom: 0
    }

    ul {
        padding-left: 20px;
        margin-bottom: 15px;
        line-height: 17px;
    }

    .year {
    }

    @media screen and (min-width: 1024px) {
        padding: 80px 0;
    }
}
